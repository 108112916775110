<template lang="pug">
  div
    el-card(body-style="padding:0")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button-group
              el-button(plain @click="create" icon="el-icon-plus") Oluştur
              el-button(plain @click="create" icon="el-icon-download") Excel
          el-form-item
            el-input.pull-right(suffix-icon="el-icon-search" placeholder="Ara")
</template>
<script>
export default {
  name: 'stock',
  methods: {
    create(){
      
    }
  }
}
</script>